export function isObject(obj) {
  if (
    Object.prototype.toString.call(obj).toLocaleLowerCase()
    === '[object object]'
  ) {
    return true;
  }
  return false;
}

export function getQueryString(name) {
  const search = window.location.href.split('?')[1];
  if (search) {
    const params = search.split('&');
    const obj = {};

    for (let i = 0, l = params.length; i < l; i++) {
      const tmp = params[i].split('=');
      obj[tmp[0]] = tmp[1];
    }

    return obj[name] || '';
  }
  return '';
}

export function isArray(obj) {
  if (
    Object.prototype.toString.call(obj).toLocaleLowerCase() === '[object array]'
  ) {
    return true;
  }
  return false;
}

export function isString(obj) {
  if (
    Object.prototype.toString.call(obj).toLocaleLowerCase()
    === '[object string]'
  ) {
    return true;
  }
  return false;
}

export function isM3u8(url) {
  return /\.m3u8$/.test(url);
}

export function getVideoSourceOption(url) {
  let sourceObj = {};
  if (isM3u8(url)) {
    sourceObj = {
      type: 'application/x-mpegURL',
    };
  } else {
    sourceObj = {
      type: 'video/mp4',
    };
  }

  return { ...sourceObj, src: url };
}

export function debounce(func, wait, immediate) {
  let timeout;

  return function (...args) {
    const context = this;

    if (timeout) clearTimeout(timeout);
    if (immediate) {
      // 如果已经执行过，不再执行
      const callNow = !timeout;
      timeout = setTimeout(() => {
        timeout = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    }
  };
}

export function isAndroid() {
  let res;
  if (/(Android)/i.test(navigator.userAgent)) {
    // 安卓机
    res = true;
  } else {
    res = false;
  }
  return res;
}

export function isIPhone() {
  let res;
  if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    // 安卓机
    res = true;
  } else {
    res = false;
  }
  return res;
}

// 判断设备类型
export function userAgentType() {
  const ua = navigator.userAgent;
  const isWindowsPhone = /(?:Windows Phone)/.test(ua);
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  const isAndroid = /(?:Android)/.test(ua);
  const isFireFox = /(?:Firefox)/.test(ua);
  // let isChrome = /(?:Chrome|CriOS)/.test(ua);
  const isTablet = /(?:iPad|PlayBook)/.test(ua)
    || (isAndroid && !/(?:Mobile)/.test(ua))
    || (isFireFox && /(?:Tablet)/.test(ua));
  const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  const isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet,
    isPhone,
    isAndroid,
    isPc,
  };
}

export function uniqueArrary(arr) {
  // 数组的去重
  const res = new Map();
  return arr.filter((a) => !res.has(a) && res.set(a, 1));
}

/*
 ** 添加移除class类
 * */
export function addClass(element, className) {
  if (!new RegExp('(^|\\s)' + className + '(\\s|$)').test(element.className)) {
    element.className += ' ' + className;
  }
}
export function removeClass(element, className) {
  element.className = element.className.replace(
    new RegExp('(^|\\s)' + className + '(?=(\\s|$))', 'g'),
    '',
  );
}

export function isNumber(val) {
  const regPos = /^\d+(\.\d+)?$/; // 非负浮点数
  const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; // 负浮点数
  if (regPos.test(val) || regNeg.test(val)) {
    return true;
  }
  return false;
}

export function getEmptyCount(str) {
  let num = 0;
  str.replace(/___\d___/g, () => {
    num++;
  });
  str.replace(/##\$\$##/g, () => {
    num++;
  });
  return num;
}

// 判断机型
export function os() {
  let ua = navigator.userAgent;
  let isWindowsPhone = /(?:Windows Phone)/.test(ua);
  let isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  let isAndroid = /(?:Android)/.test(ua);
  let isFireFox = /(?:Firefox)/.test(ua);
  let isTablet = /(?:iPad|PlayBook)/.test(ua)
    || (isAndroid && !/(?:Mobile)/.test(ua))
    || (isFireFox && /(?:Tablet)/.test(ua));
  let isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  let isPc = !isPhone && !isAndroid && !isSymbian;
  let isIpadWeb = /(?:Safari)/.test(ua);
  return {
    isTablet,
    isPhone,
    isAndroid,
    isPc,
    isIpadWeb,
  };
}

export function getYMDHMS() {
  let time = new Date();
  let y = time.getFullYear();
  let m = time.getMonth() + 1;
  m = m > 9 ? m : '0' + m;
  let d = time.getDate();
  d = d > 9 ? d : '0' + d;
  let hours = (time.getHours()).toString().padStart(2, '0');
  let minute = (time.getMinutes()).toString().padStart(2, '0');
  let second = (time.getSeconds()).toString().padStart(2, '0');
  return y + '-' + m + '-' + d + ' ' + hours + ':' + minute + ':' + second;
}

export function getNewYMDHMS() {
  let time = new Date();
  let y = time.getFullYear();
  let m = time.getMonth() + 1;
  m = m > 9 ? m : '0' + m;
  let d = time.getDate();
  d = d > 9 ? d : '0' + d;
  let hours = (time.getHours()).toString().padStart(2, '0');
  let minute = (time.getMinutes()).toString().padStart(2, '0');
  let second = (time.getSeconds()).toString().padStart(2, '0');
  return y + '.' + m + '.' + d + ' ' + hours + ':' + minute + ':' + second;
}

export function getNewsYMDHMS(time, isSecond = true) {
  time = new Date(time);
  let y = time.getFullYear();
  let m = time.getMonth() + 1;
  m = m > 9 ? m : '0' + m;
  let d = time.getDate();
  d = d > 9 ? d : '0' + d;
  let hours = (time.getHours()).toString().padStart(2, '0');
  let minute = (time.getMinutes()).toString().padStart(2, '0');
  let second = (time.getSeconds()).toString().padStart(2, '0');
  return y + '.' + m + '.' + d + ' ' + hours + ':' + minute + (isSecond ? ':' + second : '');
}

export function getNewDayYMDHMS(time) {
  time = new Date(time);
  let m = time.getMonth() + 1;
  m = m > 9 ? m : '0' + m;
  let d = time.getDate();
  d = d > 9 ? d : '0' + d;
  let hours = (time.getHours()).toString().padStart(2, '0');
  let minute = (time.getMinutes()).toString().padStart(2, '0');
  let second = (time.getSeconds()).toString().padStart(2, '0');
  return m + '.' + d + ' ' + hours + ':' + minute + ':' + second;
}

export function shareConfig(title, linkUrl, desc, favicon, contentType = 2) {
  const content = {
    imageUrl: favicon,
    title,
    desc,
    link: linkUrl,
  };
  return [
    { content, contentType, platformType: 1 }, // 微信聊天
    { content, contentType, platformType: 2 }, // 微信朋友圈
    { content, contentType, platformType: 4 }, // qq
    { content, contentType, platformType: 0 }, // 微博
    { content, contentType, platformType: 1002 }, // safari
  ];
}

export function getUrlParam(name) {
  const reg = new RegExp('(^|\\?|&)' + name + '=([^&]*)(&|$)');
  let r = window.location.href.match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}
export function getParamCode(str, name) {
  const reg = new RegExp('(^|\\?|&)' + name + '=([^&]*)(&|$)');
  let r = str.match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

export function changeParam(param) {
  return JSON.stringify(param).replace(/:/g, '=')
    .replace(/,/g, '&').replace(/{/g, '?')
    .replace(/}/g, '')
    .replace(/"/g, '');
}

export function isChinese(temp) {
  const re = /.*[\u4e00-\u9fa5]+.*$/;
  if (re.test(temp)) return true;
  return false;
}

export function checkPhone(number) { // 校验手机号11
  const myreg = /^(((13[0-9]{1})|(14[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(15[0-3]{1})|(15[4-9]{1})|(18[0-9]{1})|(19[0-9]{1})|(199))+\d{8})$/;
  return myreg.test(number);
}

// 表情转码
export function utf16toEntities(str) {
  const patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
  str = str.replace(patt, (char) => {
    let H;
    let L;
    let code;
    let s;

    if (char.length === 2) {
      H = char.charCodeAt(0); // 取出高位
      L = char.charCodeAt(1); // 取出低位
      code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
      s = `&#${code};`;
    } else {
      s = char;
    }

    return s;
  });

  return str;
}

export function isWordsAndNumber(str) {
  const zg = /^[0-9a-zA-Z]*$/;
  return zg.test(str);
}

export function randomString(len) {
  len = len || 15;
  const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  let maxPos = $chars.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

export function yuuid() {
  let s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';
  s[19] = hexDigits.substr((s[19] && 0x3 | 0x8), 1);
  s[8] = '-';
  s[13] = '-';
  s[18] = '-';
  s[23] = '-';
  let uuid = s.join('');
  return uuid;
}

export function downloadAppUrl() {
  const config = {
    iosUrl: 'https://itunes.apple.com/us/app/%E6%9C%89%E9%B1%BC%E7%AE%80%E8%AE%B0-%E7%AE%80%E6%B4%81%E8%AE%B0%E8%B4%A6%E7%90%86%E8%B4%A2%E7%AE%A1%E5%AE%B6/id1227412316?l=zh&ls=1&mt=8',
    androidUrl: 'http://download.yofish.com/jz/yyjz.apk',
  };
  return config;
}

export function hasScrollbar() {
  return document.body.scrollHeight > (window.innerHeight || document.documentElement.clientHeight);
}

export function getScrollTop() {
  let scrollTop = 0;
  let bodyScrollTop = 0;
  let documentScrollTop = 0;
  if (document.body) {
    bodyScrollTop = document.body.scrollTop;
  }
  if (document.documentElement) {
    documentScrollTop = document.documentElement.scrollTop;
  }
  scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
  return scrollTop;
}

export function getScrollHeight() {
  let scrollHeight = 0;
  let bodyScrollHeight = 0;
  let documentScrollHeight = 0;
  if (document.body) {
    bodyScrollHeight = document.body.scrollHeight;
  }
  if (document.documentElement) {
    documentScrollHeight = document.documentElement.scrollHeight;
  }
  // eslint-disable-next-line max-len
  scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
  return scrollHeight;
}

export function getWindowHeight() {
  let windowHeight = 0;
  if (document.compatMode === 'CSS1Compat') {
    windowHeight = document.documentElement.clientHeight;
  } else {
    windowHeight = document.body.clientHeight;
  }
  return windowHeight;
}

export function base64Encode(str) {
  let c1;
  let c2;
  let c3;
  const base64EncodeChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  let i = 0;
  let len = str.length;
  let string = '';

  while (i < len) {
    c1 = str.charCodeAt(i++) & 0xff;
    if (i === len) {
      string += base64EncodeChars.charAt(c1 >> 2);
      string += base64EncodeChars.charAt((c1 & 0x3) << 4);
      string += '==';
      break;
    }
    c2 = str.charCodeAt(i++);
    if (i === len) {
      string += base64EncodeChars.charAt(c1 >> 2);
      string += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
      string += base64EncodeChars.charAt((c2 & 0xF) << 2);
      string += '=';
      break;
    }
    c3 = str.charCodeAt(i++);
    string += base64EncodeChars.charAt(c1 >> 2);
    string += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
    string += base64EncodeChars.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
    string += base64EncodeChars.charAt(c3 & 0x3F);
  }
  return string;
}

export function base64Decode(str) {
  let c1;
  let c2;
  let c3;
  let c4;
  // eslint-disable-next-line no-array-constructor
  const base64DecodeChars = new Array(
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63, 52, 53, 54, 55, 56, 57,
    58, 59, 60, 61, -1, -1, -1, -1, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6,
    7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    25, -1, -1, -1, -1, -1, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
    37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1,
    -1, -1,
  );
  let i = 0;
  let len = str.length;
  let string = '';
  while (i < len) {
    do {
      c1 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
    } while (
      i < len && c1 === -1
    );

    if (c1 === -1) break;

    do {
      c2 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
    } while (
      i < len && c2 === -1
    );

    if (c2 === -1) break;

    string += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4));

    do {
      c3 = str.charCodeAt(i++) & 0xff;
      if (c3 === 61) return string;

      c3 = base64DecodeChars[c3];
    } while (
      i < len && c3 === -1
    );

    if (c3 === -1) break;

    string += String.fromCharCode(((c2 & 0XF) << 4) | ((c3 & 0x3C) >> 2));
    do {
      c4 = str.charCodeAt(i++) & 0xff;
      if (c4 === 61) return string;
      c4 = base64DecodeChars[c4];
    } while (
      i < len && c4 === -1
    );

    if (c4 === -1) break;

    string += String.fromCharCode(((c3 & 0x03) << 6) | c4);
  }
  return string;
}
